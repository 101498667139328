import { Product } from "@/interfaces/account/Home/Collections";
import { NextPage } from "next";
import Link from "next/link";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "next/image";
import { useCart } from "@/context/CartContext";
import { Cart } from "@/interfaces/account/Home/Cart";
import { useContext, useState, useMemo } from "react";
import AuthContext from "@/context/AuthContext";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { URLS } from "@/utils/urls";
import { showToastError, showToastSuccess } from "@/utils/toastFunctions";
import { toastMessages } from "@/constants/toast-messages";
import LoadingDataSpinner from "@/components/Frontend/LoadingDataSpinner";

interface Props {
  product: Product;
}

const ProductItem: NextPage<Props> = ({ product }) => {
  // Destructure commonly used values
  const {
    id,
    title,
    slug,
    main_image_thumbnail,
    price,
    discount_price,
    in_stock,
    in_stock_count,
    store,
    wishlists,
  } = product;
  const { user, authTokens } = useContext(AuthContext);
  const { addItemToCart, cartItems } = useCart();

  // State management
  const [isItemAddingToWishlist, setIsItemAddingToWishlist] = useState(false);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [isProductInWishlist, setIsProductInWishlist] = useState(
    wishlists.includes(user?.user_id)
  );

  // Memoize derived values
  const productUrl = useMemo(
    () => `/store/${store.slug}/products/${slug}`,
    [store.slug, slug]
  );

  // Memoize cart status
  const cartItem = useMemo(
    () => cartItems.find((item) => item.product.id === id),
    [cartItems, id]
  );

  const isProductInCart = Boolean(cartItem);

  const AddItemToCart = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!in_stock) {
      showToastError("Product is out of stock");
      return;
    }

    setIsAddingToCart(true);
    try {
      const newQuantity = cartItem ? cartItem.quantity + 1 : 1;

      if (newQuantity > in_stock_count) {
        showToastError(`Cannot exceed available stock of ${in_stock_count}`);
        return;
      }

      const cartItemObj: Cart = {
        product,
        quantity: newQuantity,
      };
      addItemToCart(cartItemObj, false); // Pass false to indicate this is not from detail page
    } catch (error) {
      console.error("Error adding to cart:", error);
      showToastError("Failed to add item to cart");
    } finally {
      setIsAddingToCart(false);
    }
  };

  const ModifyWishlist = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!authTokens?.access || !user) {
      showToastError("User not Logged in");
      return;
    }

    setIsItemAddingToWishlist(true);
    try {
      const response = await fetch(URLS?.modifyWishListUrl(id), {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to_wish_list: !isProductInWishlist,
        }),
        method: "PUT",
      });

      if (!response.ok) {
        throw new Error("Failed to modify wishlist");
      }

      setIsProductInWishlist(!isProductInWishlist);
      showToastSuccess(
        !isProductInWishlist
          ? toastMessages.productAddedToWishlist
          : toastMessages.productRemovedFromWishList
      );
    } catch (error) {
      console.error("Error:", error);
      showToastError("Failed to modify wishlist");
    } finally {
      setIsItemAddingToWishlist(false);
    }
  };

  const getButtonText = () => {
    if (isAddingToCart) {
      return <LoadingDataSpinner spinnerHeight="h-6 w-6" color="white" />;
    }
    if (!in_stock) {
      return "Out of Stock";
    }
    return isProductInCart ? "In Cart" : "Add to Cart";
  };

  return (
    <div className="m-[6px]">
      <div className="max-w-[248.5px] flex flex-col gap-[16px] sm:max-w-[165px] md:max-w-[190px] ">
        <div className="w-[248.5px] rounded-[4px] group relative sm:max-w-[165px] md:max-w-[190px] cursor-pointer sm:h-[160px] md:h-[160px] h-[250px] bg-[#F5F5F5] flex items-center justify-center overflow-hidden border border-[#4444441F]">
          <Link href={productUrl}>
            <Image
              height={153}
              width={284.5}
              src={main_image_thumbnail}
              alt={title}
              className="min-h-[250px] min-w-[248.5px] sm:min-w-[165px] md:min-w-[190px] group-hover:scale-125 ease-in duration-150"
            />
          </Link>

          {/* Wishlist button */}
          <div
            onClick={ModifyWishlist}
            className={`hover:opacity-75 w-11 h-11 text-[20px] sm:hidden md:hidden flex items-center justify-center absolute 
              ${isProductInWishlist ? "bg-orange" : "bg-white"}
              ${isItemAddingToWishlist && "animate-pulse"}
              p-[8px] transition-all duration-700 translate-y-5 group-hover:translate-y-0 
              opacity-0 invisible group-hover:opacity-100 group-hover:visible 
              rounded-full shadow-[0px_4px_12px_0px_#00000017] top-[12px] right-[12px]`}
          >
            <FavoriteBorderOutlinedIcon
              fontSize="inherit"
              className={`cursor-pointer ${
                isProductInWishlist ? "text-white" : "text-black"
              }`}
            />
          </div>

          {/* Add to cart button */}
          <button
            onClick={AddItemToCart}
            disabled={isAddingToCart || !in_stock}
            className={`absolute sm:hidden md:hidden bottom-0 
              ${
                in_stock
                  ? "bg-orange hover:bg-[#E8794E]"
                  : "bg-gray-400 cursor-not-allowed"
              }
              w-full h-[41px] font-poppins transition-all duration-700 
              opacity-0 invisible group-hover:opacity-100 group-hover:visible 
              text-[16px] font-[500] text-white`}
          >
            {getButtonText()}
          </button>
        </div>

        <Link href={productUrl} className="pt-[16px] cursor-pointer">
          <div className="flex flex-col gap-1">
            <h4 className="text-grey-200 hover:text-orange truncate max-w-[248.5px] text-[14px] font-poppins font-[400]">
              {title}
            </h4>
            <span
              className={`text-[12px] font-poppins ${
                in_stock ? "text-[#36bf6f]" : "text-red-500"
              }`}
            >
              {in_stock ? `In Stock (${in_stock_count})` : "Out of Stock"}
            </span>
          </div>
          <div className="flex justify-between gap-[10px] cursor-pointer mt-1">
            <h4 className="text-[14px] line-through text-grey-200 font-poppins font-[400]">
              ₹{price}
            </h4>
            <h4 className="text-[14px] text-grey-400 font-poppins font-[400]">
              ₹{discount_price}
            </h4>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProductItem;
